export const STATES_LIST = "STATES_LIST";
export const APPOINTMENT_TYPES_LIST = "APPOINTMENT_TYPES_LIST";
export const APPOINTMENTS_LIST = "APPOINTMENTS_LIST";
export const AVAILABILITY_LIST = "AVAILABILITY_LIST";
export const CARE_TEAM = "CARE_TEAM";
export const CONTACT_LIST = "CONTACT_LIST";
export const CONTACT_ROLES = "CONTACT_ROLES";
export const CONTACT_INFORMATION = "CONTACT_INFORMATION";
export const COORDINATORS_LIST = "COORDINATORS_LIST";
export const DICTIONARY_LIST = "DICTIONARY_LIST";
export const EVENTS_LIST = "EVENTS_LIST";
export const INVITATION_VIEW = "INVITATION_VIEW";
export const INVITATIONS_LIST = "INVITATIONS_LIST";
export const ME = "ME";
export const MY_CARE_RECIPIENT = "MY_CARE_RECIPIENT";
export const MEDICAL_RECORDS_LIST = "MEDICAL_RECORDS_LIST";
export const MEDICAL_RECORD_SUMMARY_LIST = "MEDICAL_RECORD_SUMMARY_LIST";
export const MEMBER_PROFILES_LIST = "MEMBER_PROFILES_LIST";
export const MESSAGING = "MESSAGING";
export const MESSAGING_ENABLED = "MESSAGING_ENABLED";
export const MESSAGE_THREAD = "MESSAGE_THREAD";
export const MESSAGING_THREAD_SUMMARIES = "MESSAGING_THREAD_SUMMARIES";
export const OFFICE_STATUS = "OFFICE_STATUS";
export const PATIENT_ACCOUNT = "PATIENT_ACCOUNT";
export const PATIENT_PROFILE = "PATIENT_PROFILE";
export const PATIENT_PROFILE_EVENTS = "PATIENT_PROFILE_EVENTS";
export const PATIENT_VIEW = "PATIENT_VIEW";
export const REFERRAL_VIEW = "REFERRAL_VIEW";
export const REFERRALS_LIST = "REFERRALS_LIST";
export const PATIENTS_LIST = "PATIENTS_LIST";
export const TIMEZONES_LIST = "TIMEZONES_LIST";
export const APPOINTMENT_SLOTS = "APPOINTMENT_SLOTS";
export const REGISTRATION_APPOINTMENT_SLOTS = "REGISTRATION_APPOINTMENT_SLOTS";
export const SLACK_LIVE_CHAT_POLL = "SLACK_LIVE_CHAT_POLL";

/* eslint-disable */

export const AllTypesProps: Record<string,any> = {
	AppointmentSlotsInput:{
		appointment_type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	AppointmentsFilterTemporalStatus: "enum",
	AppointmentsInput:{
		after:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		limit:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		sort:{
			type:"AppointmentsSort",
			array:false,
			arrayRequired:false,
			required:false
		},
		temporalStatus:{
			type:"AppointmentsFilterTemporalStatus",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	AppointmentsSort: "enum",
	AttributionInput:{
		adgroup:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		campaign:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		content:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		medium:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		source:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		term:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	CancelAppointmentInput:{
		appointmentId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	CaregiverInput:{
		birth_date:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		birth_sex:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		first_name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		phone_number:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		relationship:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		state:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		zip_code:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContactUsInput:{
		email:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		question:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	CreateAppointmentInput:{
		appointmentType:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		participants:{
			type:"String",
			array:true,
			arrayRequired:true,
			required:true
		},
		startAt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		subject:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		timezone:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	CreateSourceMemberTokenInput:{
		actor_member_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		subject_member_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	IsValidDiseaseStateInput:{
		diagnosis:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		state:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	MarkMessagingThreadAsReadInput:{
		messagingThreadId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	MessageAttachment:{
		key:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	PatientInput:{
		birth_date:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		birth_sex:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		diagnosis:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		first_name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		has_confirmed_diagnosis:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		phone_number:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		relationship:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		state:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		zip_code:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PreRegistrationInput:{
		secret:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	RegisterPatientInput:{
		appointment_participant:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		appointment_type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		attribution:{
			type:"AttributionInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		call_type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		caregiver:{
			type:"CaregiverInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		patient:{
			type:"PatientInput",
			array:false,
			arrayRequired:false,
			required:true
		},
		pre_registration_secret:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		scheduled_at:{
			type:"timestamp",
			array:false,
			arrayRequired:false,
			required:false
		},
		timezone:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		wants_email_schedule:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	RegistrationSlotsInput:{
		appointment_type_key:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		state:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	RescheduleAppointmentInput:{
		appointmentId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:true
		},
		participants:{
			type:"String",
			array:true,
			arrayRequired:true,
			required:true
		},
		startAt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		subject:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	SendMessageInput:{
		attachments:{
			type:"MessageAttachment",
			array:true,
			arrayRequired:false,
			required:false
		},
		careTeamMemberId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		content:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		messagingThreadId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SignedUrlInput:{
		fileName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		fileType:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	SlackInboundPhonePostInput:{
		_cioanonid:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_cioid:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		currentURL:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		mixpanel:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		phoneNumber:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		ts:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SlackLiveChatPollInput:{
		latest_ts:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		postUUID:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		ts:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	SlackLiveChatPostInput:{
		_cioanonid:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_cioid:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		currentURL:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		message:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		mixpanel:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		postUUID:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		ts:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	String_comparison_exp:{
		_eq:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_ilike:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		_iregex:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_like:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nilike:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		_niregex:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nlike:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nregex:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nsimilar:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_regex:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_similar:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ValidateEmailInput:{
		careRecipientEmail:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	mutation_root:{
		cancelAppointment:{
			args:{
				type:"CancelAppointmentInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		contact_us:{
			args:{
				type:"ContactUsInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		createAppointment:{
			args:{
				type:"CreateAppointmentInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		createSignedUrl:{
			args:{
				type:"SignedUrlInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createSourceMemberToken:{
			args:{
				type:"CreateSourceMemberTokenInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		delete_synapticure_static_data:{
			where:{
				type:"synapticure_static_data_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_synapticure_static_data_by_pk:{
			uuid:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		insert_synapticure_static_data:{
			objects:{
				type:"synapticure_static_data_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"synapticure_static_data_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_synapticure_static_data_one:{
			object:{
				type:"synapticure_static_data_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"synapticure_static_data_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		markMessagingThreadAsRead:{
			args:{
				type:"MarkMessagingThreadAsReadInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		registerPatient:{
			args:{
				type:"RegisterPatientInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		rescheduleAppointment:{
			args:{
				type:"RescheduleAppointmentInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		sendMessage:{
			args:{
				type:"SendMessageInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		slackInboundPhonePost:{
			args:{
				type:"SlackInboundPhonePostInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		slackLiveChatPost:{
			args:{
				type:"SlackLiveChatPostInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_synapticure_static_data:{
			_set:{
				type:"synapticure_static_data_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"synapticure_static_data_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_synapticure_static_data_by_pk:{
			_set:{
				type:"synapticure_static_data_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"synapticure_static_data_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateEmail:{
			args:{
				type:"ValidateEmailInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		}
	},
	order_by: "enum",
	query_root:{
		appointment_slots:{
			args:{
				type:"AppointmentSlotsInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		appointments:{
			args:{
				type:"AppointmentsInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		isValidDiseaseState:{
			args:{
				type:"IsValidDiseaseStateInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		messageThread:{
			afterGroup:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			firstGroups:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			messageThreadId:{
				type:"ID",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		messageThreadSummaries:{
			after:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		preRegistrationData:{
			args:{
				type:"PreRegistrationInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		registration_appointment_slots:{
			args:{
				type:"RegistrationSlotsInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		slackLiveChatPoll:{
			args:{
				type:"SlackLiveChatPollInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		synapticure_static_data:{
			distinct_on:{
				type:"synapticure_static_data_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"synapticure_static_data_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"synapticure_static_data_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		synapticure_static_data_aggregate:{
			distinct_on:{
				type:"synapticure_static_data_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"synapticure_static_data_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"synapticure_static_data_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		synapticure_static_data_by_pk:{
			uuid:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		}
	},
	subscription_root:{
		synapticure_static_data:{
			distinct_on:{
				type:"synapticure_static_data_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"synapticure_static_data_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"synapticure_static_data_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		synapticure_static_data_aggregate:{
			distinct_on:{
				type:"synapticure_static_data_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"synapticure_static_data_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"synapticure_static_data_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		synapticure_static_data_by_pk:{
			uuid:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		}
	},
	synapticure_static_data_aggregate_fields:{
		count:{
			columns:{
				type:"synapticure_static_data_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	synapticure_static_data_bool_exp:{
		_and:{
			type:"synapticure_static_data_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"synapticure_static_data_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"synapticure_static_data_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		created_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		dictionary_type:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		key_name:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		uuid:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	synapticure_static_data_constraint: "enum",
	synapticure_static_data_insert_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dictionary_type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		key_name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		uuid:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	synapticure_static_data_on_conflict:{
		constraint:{
			type:"synapticure_static_data_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"synapticure_static_data_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"synapticure_static_data_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	synapticure_static_data_order_by:{
		created_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		dictionary_type:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		key_name:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		uuid:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	synapticure_static_data_pk_columns_input:{
		uuid:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	synapticure_static_data_select_column: "enum",
	synapticure_static_data_set_input:{
		created_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dictionary_type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		key_name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		uuid:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	synapticure_static_data_update_column: "enum",
	timestamp: "String",
	timestamptz: "String",
	timestamptz_comparison_exp:{
		_eq:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gte:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"timestamptz",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lte:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"timestamptz",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	uuid: "String",
	uuid_comparison_exp:{
		_eq:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gt:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gte:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"uuid",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lt:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lte:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"uuid",
			array:true,
			arrayRequired:false,
			required:true
		}
	}
}

export const ReturnTypes: Record<string,any> = {
	cached:{
		ttl:"Int",
		refresh:"Boolean"
	},
	Appointment:{
		appointmentTypeId:"ID",
		date:"String",
		id:"ID",
		subject:"String",
		subtitle:"String",
		time:"String",
		title:"String",
		videoLink:"String"
	},
	AppointmentSlotOutput:{
		clinicianAvatar:"String",
		clinicianDisplayTitle:"String",
		clinicianSourceId:"String",
		startAt:"timestamp"
	},
	AppointmentTypeV2:{
		displayName:"String",
		id:"ID"
	},
	CancelAppointmentOutput:{
		success:"Boolean"
	},
	CareTeam:{
		avatar:"String",
		fullName:"String",
		id:"String",
		isCareCoordinator:"Boolean",
		role:"String"
	},
	CareTeamMember:{
		avatar:"String",
		fullName:"String",
		id:"String"
	},
	ContactUsOutput:{
		success:"Boolean"
	},
	CreateAppointmentOutput:{
		success:"Boolean"
	},
	CreateSourceMemberTokenOutput:{
		token:"String"
	},
	IsValidDiseaseStateOutput:{
		isValidDiseaseState:"Boolean"
	},
	MarkMessagingThreadAsReadOutput:{
		messagingThreadId:"ID",
		success:"Boolean"
	},
	MeOutput:{
		account_id:"String",
		external_id:"String",
		populations:"String"
	},
	MemberProfile:{
		emailAddress:"String",
		firstName:"String",
		fullName:"String",
		homeAddressCity:"String",
		homeAddressLineOne:"String",
		homeAddressPostalCode:"String",
		homeAddressState:"String",
		id:"ID",
		lastName:"String",
		phoneNumber:"String",
		preferredName:"String",
		pronouns:"String",
		role:"String",
		shippingAddressCity:"String",
		shippingAddressLineOne:"String",
		shippingAddressPostalCode:"String",
		shippingAddressState:"String"
	},
	MessageThreadMessageAttachment:{
		description:"String",
		id:"ID",
		url:"String"
	},
	Messaging:{
		id:"ID",
		menuLabel:"String",
		numberOfUnreadMessages:"String"
	},
	MessagingThread:{
		hasMoreMessages:"Boolean",
		hasUnreadMessages:"Boolean",
		id:"ID",
		initialCareTeamMember:"CareTeamMember",
		isNewMessagesAllowed:"Boolean",
		messageGroups:"MessagingThreadMessageGroup",
		subject:"String"
	},
	MessagingThreadMessage:{
		content:"String",
		id:"ID"
	},
	MessagingThreadMessageGroup:{
		attachments:"MessageThreadMessageAttachment",
		id:"ID",
		isSentByYou:"Boolean",
		messages:"MessagingThreadMessage",
		senderAvatar:"String",
		senderDisplayName:"String",
		sentAt:"String"
	},
	MessagingThreadSummary:{
		associatedMessageThreadId:"ID",
		content:"String",
		id:"ID",
		lastSenderAvatar:"String",
		lastSenderDisplayName:"String",
		numberOfUnreadMessages:"String",
		subject:"String",
		wasArchivedOn:"String"
	},
	OfficeStatus:{
		message:"String",
		showBanner:"Boolean"
	},
	PreRegistrationOutput:{
		email:"String",
		firstName:"String",
		lastName:"String"
	},
	RegisterPatientOutput:{
		external_id:"String",
		source_actor_member_id:"String",
		source_subject_member_id:"String",
		success:"Boolean"
	},
	RescheduleAppointmentOutput:{
		success:"Boolean"
	},
	SendMessageOutput:{
		messagingThreadId:"ID",
		success:"Boolean"
	},
	SignedUrlOutput:{
		key:"String",
		url:"String"
	},
	SlackInboundPhonePostOutput:{
		ts:"String"
	},
	SlackLiveChatMessage:{
		text:"String",
		ts:"String",
		username:"String"
	},
	SlackLiveChatPollOutput:{
		messages:"SlackLiveChatMessage"
	},
	SlackLiveChatPostOutput:{
		postUUID:"String",
		ts:"String"
	},
	StateOutput:{
		abbreviation:"String",
		capital:"String",
		contiguous:"Boolean",
		name:"String",
		territory:"Boolean"
	},
	TimeZoneOutput:{
		abbreviation:"String",
		alternativeName:"String",
		continentCode:"String",
		continentName:"String",
		countryCode:"String",
		countryName:"String",
		group:"String",
		mainCities:"String",
		name:"String",
		rawFormat:"String",
		rawOffsetInMinutes:"String"
	},
	ValidateEmailOutput:{
		error:"String",
		message:"String"
	},
	mutation_root:{
		cancelAppointment:"CancelAppointmentOutput",
		contact_us:"ContactUsOutput",
		createAppointment:"CreateAppointmentOutput",
		createSignedUrl:"SignedUrlOutput",
		createSourceMemberToken:"CreateSourceMemberTokenOutput",
		delete_synapticure_static_data:"synapticure_static_data_mutation_response",
		delete_synapticure_static_data_by_pk:"synapticure_static_data",
		insert_synapticure_static_data:"synapticure_static_data_mutation_response",
		insert_synapticure_static_data_one:"synapticure_static_data",
		markMessagingThreadAsRead:"MarkMessagingThreadAsReadOutput",
		registerPatient:"RegisterPatientOutput",
		rescheduleAppointment:"RescheduleAppointmentOutput",
		sendMessage:"SendMessageOutput",
		slackInboundPhonePost:"SlackInboundPhonePostOutput",
		slackLiveChatPost:"SlackLiveChatPostOutput",
		update_synapticure_static_data:"synapticure_static_data_mutation_response",
		update_synapticure_static_data_by_pk:"synapticure_static_data",
		validateEmail:"ValidateEmailOutput"
	},
	query_root:{
		appointmentTypes:"AppointmentTypeV2",
		appointment_slots:"AppointmentSlotOutput",
		appointments:"Appointment",
		careTeam:"CareTeam",
		isValidDiseaseState:"IsValidDiseaseStateOutput",
		me:"MeOutput",
		memberProfiles:"MemberProfile",
		messageThread:"MessagingThread",
		messageThreadSummaries:"MessagingThreadSummary",
		messaging:"Messaging",
		officeStatus:"OfficeStatus",
		preRegistrationData:"PreRegistrationOutput",
		registration_appointment_slots:"AppointmentSlotOutput",
		slackLiveChatPoll:"SlackLiveChatPollOutput",
		states:"StateOutput",
		synapticure_static_data:"synapticure_static_data",
		synapticure_static_data_aggregate:"synapticure_static_data_aggregate",
		synapticure_static_data_by_pk:"synapticure_static_data",
		timezones:"TimeZoneOutput"
	},
	subscription_root:{
		synapticure_static_data:"synapticure_static_data",
		synapticure_static_data_aggregate:"synapticure_static_data_aggregate",
		synapticure_static_data_by_pk:"synapticure_static_data"
	},
	synapticure_static_data:{
		created_at:"timestamptz",
		description:"String",
		dictionary_type:"String",
		key_name:"String",
		updated_at:"timestamptz",
		uuid:"uuid"
	},
	synapticure_static_data_aggregate:{
		aggregate:"synapticure_static_data_aggregate_fields",
		nodes:"synapticure_static_data"
	},
	synapticure_static_data_aggregate_fields:{
		count:"Int",
		max:"synapticure_static_data_max_fields",
		min:"synapticure_static_data_min_fields"
	},
	synapticure_static_data_max_fields:{
		created_at:"timestamptz",
		description:"String",
		dictionary_type:"String",
		key_name:"String",
		updated_at:"timestamptz",
		uuid:"uuid"
	},
	synapticure_static_data_min_fields:{
		created_at:"timestamptz",
		description:"String",
		dictionary_type:"String",
		key_name:"String",
		updated_at:"timestamptz",
		uuid:"uuid"
	},
	synapticure_static_data_mutation_response:{
		affected_rows:"Int",
		returning:"synapticure_static_data"
	}
}
/* eslint-disable */

import { AllTypesProps, ReturnTypes } from './const';
type ZEUS_INTERFACES = never
type ZEUS_UNIONS = never

export type ValueTypes = {
    ["Appointment"]: AliasType<{
	appointmentTypeId?:true,
	date?:true,
	id?:true,
	subject?:true,
	subtitle?:true,
	time?:true,
	title?:true,
	videoLink?:true,
		__typename?: true
}>;
	["AppointmentSlotOutput"]: AliasType<{
	clinicianAvatar?:true,
	clinicianDisplayTitle?:true,
	clinicianSourceId?:true,
	startAt?:true,
		__typename?: true
}>;
	["AppointmentSlotsInput"]: {
	appointment_type:string
};
	["AppointmentTypeV2"]: AliasType<{
	displayName?:true,
	id?:true,
		__typename?: true
}>;
	["AppointmentsFilterTemporalStatus"]:AppointmentsFilterTemporalStatus;
	["AppointmentsInput"]: {
	after?:string | null,
	limit?:number | null,
	sort?:ValueTypes["AppointmentsSort"] | null,
	temporalStatus?:ValueTypes["AppointmentsFilterTemporalStatus"][]
};
	["AppointmentsSort"]:AppointmentsSort;
	["AttributionInput"]: {
	adgroup?:string | null,
	campaign?:string | null,
	content?:string | null,
	medium?:string | null,
	source?:string | null,
	term?:string | null
};
	["CancelAppointmentInput"]: {
	appointmentId:string
};
	["CancelAppointmentOutput"]: AliasType<{
	success?:true,
		__typename?: true
}>;
	["CareTeam"]: AliasType<{
	avatar?:true,
	fullName?:true,
	id?:true,
	isCareCoordinator?:true,
	role?:true,
		__typename?: true
}>;
	["CareTeamMember"]: AliasType<{
	avatar?:true,
	fullName?:true,
	id?:true,
		__typename?: true
}>;
	["CaregiverInput"]: {
	birth_date?:ValueTypes["timestamptz"] | null,
	birth_sex?:string | null,
	email?:string | null,
	first_name?:string | null,
	id?:ValueTypes["uuid"] | null,
	last_name?:string | null,
	phone_number?:string | null,
	relationship?:ValueTypes["uuid"] | null,
	state?:string | null,
	zip_code?:string | null
};
	["ContactUsInput"]: {
	email:string,
	question:string
};
	["ContactUsOutput"]: AliasType<{
	success?:true,
		__typename?: true
}>;
	["CreateAppointmentInput"]: {
	appointmentType:string,
	participants:string[],
	startAt:string,
	subject?:string | null,
	timezone:string
};
	["CreateAppointmentOutput"]: AliasType<{
	success?:true,
		__typename?: true
}>;
	["CreateSourceMemberTokenInput"]: {
	actor_member_id:string,
	subject_member_id:string
};
	["CreateSourceMemberTokenOutput"]: AliasType<{
	token?:true,
		__typename?: true
}>;
	["IsValidDiseaseStateInput"]: {
	diagnosis:string,
	state:string
};
	["IsValidDiseaseStateOutput"]: AliasType<{
	isValidDiseaseState?:true,
		__typename?: true
}>;
	["MarkMessagingThreadAsReadInput"]: {
	messagingThreadId:string
};
	["MarkMessagingThreadAsReadOutput"]: AliasType<{
	messagingThreadId?:true,
	success?:true,
		__typename?: true
}>;
	["MeOutput"]: AliasType<{
	account_id?:true,
	external_id?:true,
	populations?:true,
		__typename?: true
}>;
	["MemberProfile"]: AliasType<{
	emailAddress?:true,
	firstName?:true,
	fullName?:true,
	homeAddressCity?:true,
	homeAddressLineOne?:true,
	homeAddressPostalCode?:true,
	homeAddressState?:true,
	id?:true,
	lastName?:true,
	phoneNumber?:true,
	preferredName?:true,
	pronouns?:true,
	role?:true,
	shippingAddressCity?:true,
	shippingAddressLineOne?:true,
	shippingAddressPostalCode?:true,
	shippingAddressState?:true,
		__typename?: true
}>;
	["MessageAttachment"]: {
	key:string,
	name:string
};
	["MessageThreadMessageAttachment"]: AliasType<{
	description?:true,
	id?:true,
	url?:true,
		__typename?: true
}>;
	["Messaging"]: AliasType<{
	id?:true,
	menuLabel?:true,
	numberOfUnreadMessages?:true,
		__typename?: true
}>;
	["MessagingThread"]: AliasType<{
	hasMoreMessages?:true,
	hasUnreadMessages?:true,
	id?:true,
	initialCareTeamMember?:ValueTypes["CareTeamMember"],
	isNewMessagesAllowed?:true,
	messageGroups?:ValueTypes["MessagingThreadMessageGroup"],
	subject?:true,
		__typename?: true
}>;
	["MessagingThreadMessage"]: AliasType<{
	content?:true,
	id?:true,
		__typename?: true
}>;
	["MessagingThreadMessageGroup"]: AliasType<{
	attachments?:ValueTypes["MessageThreadMessageAttachment"],
	id?:true,
	isSentByYou?:true,
	messages?:ValueTypes["MessagingThreadMessage"],
	senderAvatar?:true,
	senderDisplayName?:true,
	sentAt?:true,
		__typename?: true
}>;
	["MessagingThreadSummary"]: AliasType<{
	associatedMessageThreadId?:true,
	content?:true,
	id?:true,
	lastSenderAvatar?:true,
	lastSenderDisplayName?:true,
	numberOfUnreadMessages?:true,
	subject?:true,
	wasArchivedOn?:true,
		__typename?: true
}>;
	["OfficeStatus"]: AliasType<{
	message?:true,
	showBanner?:true,
		__typename?: true
}>;
	["PatientInput"]: {
	birth_date?:ValueTypes["timestamptz"] | null,
	birth_sex?:string | null,
	diagnosis?:string | null,
	email?:string | null,
	first_name?:string | null,
	has_confirmed_diagnosis?:boolean | null,
	id?:ValueTypes["uuid"] | null,
	last_name?:string | null,
	phone_number?:string | null,
	relationship?:string | null,
	state?:string | null,
	zip_code?:string | null
};
	["PreRegistrationInput"]: {
	secret:string
};
	["PreRegistrationOutput"]: AliasType<{
	email?:true,
	firstName?:true,
	lastName?:true,
		__typename?: true
}>;
	["RegisterPatientInput"]: {
	appointment_participant?:string | null,
	appointment_type?:string | null,
	attribution?:ValueTypes["AttributionInput"] | null,
	call_type?:string | null,
	caregiver?:ValueTypes["CaregiverInput"] | null,
	patient:ValueTypes["PatientInput"],
	pre_registration_secret?:string | null,
	scheduled_at?:ValueTypes["timestamp"] | null,
	timezone?:string | null,
	wants_email_schedule?:boolean | null
};
	["RegisterPatientOutput"]: AliasType<{
	external_id?:true,
	source_actor_member_id?:true,
	source_subject_member_id?:true,
	success?:true,
		__typename?: true
}>;
	["RegistrationSlotsInput"]: {
	appointment_type_key:string,
	state:string
};
	["RescheduleAppointmentInput"]: {
	appointmentId:string,
	participants:string[],
	startAt:string,
	subject:string
};
	["RescheduleAppointmentOutput"]: AliasType<{
	success?:true,
		__typename?: true
}>;
	["SendMessageInput"]: {
	attachments?:(ValueTypes["MessageAttachment"] | undefined | null)[],
	careTeamMemberId?:string | null,
	content:string,
	messagingThreadId?:string | null
};
	["SendMessageOutput"]: AliasType<{
	messagingThreadId?:true,
	success?:true,
		__typename?: true
}>;
	["SignedUrlInput"]: {
	fileName:string,
	fileType:string
};
	["SignedUrlOutput"]: AliasType<{
	key?:true,
	url?:true,
		__typename?: true
}>;
	["SlackInboundPhonePostInput"]: {
	_cioanonid?:string | null,
	_cioid?:string | null,
	currentURL:string,
	mixpanel?:string | null,
	phoneNumber:string,
	ts?:string | null
};
	["SlackInboundPhonePostOutput"]: AliasType<{
	ts?:true,
		__typename?: true
}>;
	["SlackLiveChatMessage"]: AliasType<{
	text?:true,
	ts?:true,
	username?:true,
		__typename?: true
}>;
	["SlackLiveChatPollInput"]: {
	latest_ts:string,
	postUUID:string,
	ts:string
};
	["SlackLiveChatPollOutput"]: AliasType<{
	messages?:ValueTypes["SlackLiveChatMessage"],
		__typename?: true
}>;
	["SlackLiveChatPostInput"]: {
	_cioanonid?:string | null,
	_cioid?:string | null,
	currentURL:string,
	message:string,
	mixpanel?:string | null,
	postUUID?:string | null,
	ts?:string | null
};
	["SlackLiveChatPostOutput"]: AliasType<{
	postUUID?:true,
	ts?:true,
		__typename?: true
}>;
	["StateOutput"]: AliasType<{
	abbreviation?:true,
	capital?:true,
	contiguous?:true,
	name?:true,
	territory?:true,
		__typename?: true
}>;
	/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
["String_comparison_exp"]: {
	_eq?:string | null,
	_gt?:string | null,
	_gte?:string | null,
	/** does the column match the given case-insensitive pattern */
	_ilike?:string | null,
	_in?:string[],
	/** does the column match the given POSIX regular expression, case insensitive */
	_iregex?:string | null,
	_is_null?:boolean | null,
	/** does the column match the given pattern */
	_like?:string | null,
	_lt?:string | null,
	_lte?:string | null,
	_neq?:string | null,
	/** does the column NOT match the given case-insensitive pattern */
	_nilike?:string | null,
	_nin?:string[],
	/** does the column NOT match the given POSIX regular expression, case insensitive */
	_niregex?:string | null,
	/** does the column NOT match the given pattern */
	_nlike?:string | null,
	/** does the column NOT match the given POSIX regular expression, case sensitive */
	_nregex?:string | null,
	/** does the column NOT match the given SQL regular expression */
	_nsimilar?:string | null,
	/** does the column match the given POSIX regular expression, case sensitive */
	_regex?:string | null,
	/** does the column match the given SQL regular expression */
	_similar?:string | null
};
	["TimeZoneOutput"]: AliasType<{
	abbreviation?:true,
	alternativeName?:true,
	continentCode?:true,
	continentName?:true,
	countryCode?:true,
	countryName?:true,
	group?:true,
	mainCities?:true,
	name?:true,
	rawFormat?:true,
	rawOffsetInMinutes?:true,
		__typename?: true
}>;
	["ValidateEmailInput"]: {
	careRecipientEmail?:string | null,
	email:string
};
	["ValidateEmailOutput"]: AliasType<{
	error?:true,
	message?:true,
		__typename?: true
}>;
	/** mutation root */
["mutation_root"]: AliasType<{
cancelAppointment?: [{	args:ValueTypes["CancelAppointmentInput"]},ValueTypes["CancelAppointmentOutput"]],
contact_us?: [{	args?:ValueTypes["ContactUsInput"] | null},ValueTypes["ContactUsOutput"]],
createAppointment?: [{	args?:ValueTypes["CreateAppointmentInput"] | null},ValueTypes["CreateAppointmentOutput"]],
createSignedUrl?: [{	args:ValueTypes["SignedUrlInput"]},ValueTypes["SignedUrlOutput"]],
createSourceMemberToken?: [{	args?:ValueTypes["CreateSourceMemberTokenInput"] | null},ValueTypes["CreateSourceMemberTokenOutput"]],
delete_synapticure_static_data?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["synapticure_static_data_bool_exp"]},ValueTypes["synapticure_static_data_mutation_response"]],
delete_synapticure_static_data_by_pk?: [{	uuid:ValueTypes["uuid"]},ValueTypes["synapticure_static_data"]],
insert_synapticure_static_data?: [{	/** the rows to be inserted */
	objects:ValueTypes["synapticure_static_data_insert_input"][],	/** upsert condition */
	on_conflict?:ValueTypes["synapticure_static_data_on_conflict"] | null},ValueTypes["synapticure_static_data_mutation_response"]],
insert_synapticure_static_data_one?: [{	/** the row to be inserted */
	object:ValueTypes["synapticure_static_data_insert_input"],	/** upsert condition */
	on_conflict?:ValueTypes["synapticure_static_data_on_conflict"] | null},ValueTypes["synapticure_static_data"]],
markMessagingThreadAsRead?: [{	args:ValueTypes["MarkMessagingThreadAsReadInput"]},ValueTypes["MarkMessagingThreadAsReadOutput"]],
registerPatient?: [{	args:ValueTypes["RegisterPatientInput"]},ValueTypes["RegisterPatientOutput"]],
rescheduleAppointment?: [{	args:ValueTypes["RescheduleAppointmentInput"]},ValueTypes["RescheduleAppointmentOutput"]],
sendMessage?: [{	args:ValueTypes["SendMessageInput"]},ValueTypes["SendMessageOutput"]],
slackInboundPhonePost?: [{	args:ValueTypes["SlackInboundPhonePostInput"]},ValueTypes["SlackInboundPhonePostOutput"]],
slackLiveChatPost?: [{	args:ValueTypes["SlackLiveChatPostInput"]},ValueTypes["SlackLiveChatPostOutput"]],
update_synapticure_static_data?: [{	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["synapticure_static_data_set_input"] | null,	/** filter the rows which have to be updated */
	where:ValueTypes["synapticure_static_data_bool_exp"]},ValueTypes["synapticure_static_data_mutation_response"]],
update_synapticure_static_data_by_pk?: [{	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["synapticure_static_data_set_input"] | null,	pk_columns:ValueTypes["synapticure_static_data_pk_columns_input"]},ValueTypes["synapticure_static_data"]],
validateEmail?: [{	args:ValueTypes["ValidateEmailInput"]},ValueTypes["ValidateEmailOutput"]],
		__typename?: true
}>;
	/** column ordering options */
["order_by"]:order_by;
	["query_root"]: AliasType<{
	/** Query available appointment types for a member */
	appointmentTypes?:ValueTypes["AppointmentTypeV2"],
appointment_slots?: [{	args:ValueTypes["AppointmentSlotsInput"]},ValueTypes["AppointmentSlotOutput"]],
appointments?: [{	args?:ValueTypes["AppointmentsInput"] | null},ValueTypes["Appointment"]],
	careTeam?:ValueTypes["CareTeam"],
isValidDiseaseState?: [{	args?:ValueTypes["IsValidDiseaseStateInput"] | null},ValueTypes["IsValidDiseaseStateOutput"]],
	me?:ValueTypes["MeOutput"],
	memberProfiles?:ValueTypes["MemberProfile"],
messageThread?: [{	afterGroup?:string | null,	firstGroups?:number | null,	messageThreadId?:string | null},ValueTypes["MessagingThread"]],
messageThreadSummaries?: [{	after?:string | null,	first?:number | null},ValueTypes["MessagingThreadSummary"]],
	messaging?:ValueTypes["Messaging"],
officeStatus?:ValueTypes["OfficeStatus"],
preRegistrationData?: [{	args:ValueTypes["PreRegistrationInput"]},ValueTypes["PreRegistrationOutput"]],
registration_appointment_slots?: [{	args:ValueTypes["RegistrationSlotsInput"]},ValueTypes["AppointmentSlotOutput"]],
slackLiveChatPoll?: [{	args:ValueTypes["SlackLiveChatPollInput"]},ValueTypes["SlackLiveChatPollOutput"]],
	states?:ValueTypes["StateOutput"],
synapticure_static_data?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["synapticure_static_data_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["synapticure_static_data_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["synapticure_static_data_bool_exp"] | null},ValueTypes["synapticure_static_data"]],
synapticure_static_data_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["synapticure_static_data_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["synapticure_static_data_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["synapticure_static_data_bool_exp"] | null},ValueTypes["synapticure_static_data_aggregate"]],
synapticure_static_data_by_pk?: [{	uuid:ValueTypes["uuid"]},ValueTypes["synapticure_static_data"]],
	timezones?:ValueTypes["TimeZoneOutput"],
		__typename?: true
}>;
	["subscription_root"]: AliasType<{
synapticure_static_data?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["synapticure_static_data_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["synapticure_static_data_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["synapticure_static_data_bool_exp"] | null},ValueTypes["synapticure_static_data"]],
synapticure_static_data_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["synapticure_static_data_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["synapticure_static_data_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["synapticure_static_data_bool_exp"] | null},ValueTypes["synapticure_static_data_aggregate"]],
synapticure_static_data_by_pk?: [{	uuid:ValueTypes["uuid"]},ValueTypes["synapticure_static_data"]],
		__typename?: true
}>;
	/** Static data and dictionaries */
["synapticure_static_data"]: AliasType<{
	created_at?:true,
	description?:true,
	dictionary_type?:true,
	key_name?:true,
	updated_at?:true,
	uuid?:true,
		__typename?: true
}>;
	/** aggregated selection of "synapticure.static_data" */
["synapticure_static_data_aggregate"]: AliasType<{
	aggregate?:ValueTypes["synapticure_static_data_aggregate_fields"],
	nodes?:ValueTypes["synapticure_static_data"],
		__typename?: true
}>;
	/** aggregate fields of "synapticure.static_data" */
["synapticure_static_data_aggregate_fields"]: AliasType<{
count?: [{	columns?:ValueTypes["synapticure_static_data_select_column"][],	distinct?:boolean | null},true],
	max?:ValueTypes["synapticure_static_data_max_fields"],
	min?:ValueTypes["synapticure_static_data_min_fields"],
		__typename?: true
}>;
	/** Boolean expression to filter rows from the table "synapticure.static_data". All fields are combined with a logical 'AND'. */
["synapticure_static_data_bool_exp"]: {
	_and?:ValueTypes["synapticure_static_data_bool_exp"][],
	_not?:ValueTypes["synapticure_static_data_bool_exp"] | null,
	_or?:ValueTypes["synapticure_static_data_bool_exp"][],
	created_at?:ValueTypes["timestamptz_comparison_exp"] | null,
	description?:ValueTypes["String_comparison_exp"] | null,
	dictionary_type?:ValueTypes["String_comparison_exp"] | null,
	key_name?:ValueTypes["String_comparison_exp"] | null,
	updated_at?:ValueTypes["timestamptz_comparison_exp"] | null,
	uuid?:ValueTypes["uuid_comparison_exp"] | null
};
	/** unique or primary key constraints on table "synapticure.static_data" */
["synapticure_static_data_constraint"]:synapticure_static_data_constraint;
	/** input type for inserting data into table "synapticure.static_data" */
["synapticure_static_data_insert_input"]: {
	created_at?:ValueTypes["timestamptz"] | null,
	description?:string | null,
	dictionary_type?:string | null,
	key_name?:string | null,
	updated_at?:ValueTypes["timestamptz"] | null,
	uuid?:ValueTypes["uuid"] | null
};
	/** aggregate max on columns */
["synapticure_static_data_max_fields"]: AliasType<{
	created_at?:true,
	description?:true,
	dictionary_type?:true,
	key_name?:true,
	updated_at?:true,
	uuid?:true,
		__typename?: true
}>;
	/** aggregate min on columns */
["synapticure_static_data_min_fields"]: AliasType<{
	created_at?:true,
	description?:true,
	dictionary_type?:true,
	key_name?:true,
	updated_at?:true,
	uuid?:true,
		__typename?: true
}>;
	/** response of any mutation on the table "synapticure.static_data" */
["synapticure_static_data_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:true,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["synapticure_static_data"],
		__typename?: true
}>;
	/** on_conflict condition type for table "synapticure.static_data" */
["synapticure_static_data_on_conflict"]: {
	constraint:ValueTypes["synapticure_static_data_constraint"],
	update_columns:ValueTypes["synapticure_static_data_update_column"][],
	where?:ValueTypes["synapticure_static_data_bool_exp"] | null
};
	/** Ordering options when selecting data from "synapticure.static_data". */
["synapticure_static_data_order_by"]: {
	created_at?:ValueTypes["order_by"] | null,
	description?:ValueTypes["order_by"] | null,
	dictionary_type?:ValueTypes["order_by"] | null,
	key_name?:ValueTypes["order_by"] | null,
	updated_at?:ValueTypes["order_by"] | null,
	uuid?:ValueTypes["order_by"] | null
};
	/** primary key columns input for table: synapticure_static_data */
["synapticure_static_data_pk_columns_input"]: {
	uuid:ValueTypes["uuid"]
};
	/** select columns of table "synapticure.static_data" */
["synapticure_static_data_select_column"]:synapticure_static_data_select_column;
	/** input type for updating data in table "synapticure.static_data" */
["synapticure_static_data_set_input"]: {
	created_at?:ValueTypes["timestamptz"] | null,
	description?:string | null,
	dictionary_type?:string | null,
	key_name?:string | null,
	updated_at?:ValueTypes["timestamptz"] | null,
	uuid?:ValueTypes["uuid"] | null
};
	/** update columns of table "synapticure.static_data" */
["synapticure_static_data_update_column"]:synapticure_static_data_update_column;
	["timestamp"]:unknown;
	["timestamptz"]:unknown;
	/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
["timestamptz_comparison_exp"]: {
	_eq?:ValueTypes["timestamptz"] | null,
	_gt?:ValueTypes["timestamptz"] | null,
	_gte?:ValueTypes["timestamptz"] | null,
	_in?:ValueTypes["timestamptz"][],
	_is_null?:boolean | null,
	_lt?:ValueTypes["timestamptz"] | null,
	_lte?:ValueTypes["timestamptz"] | null,
	_neq?:ValueTypes["timestamptz"] | null,
	_nin?:ValueTypes["timestamptz"][]
};
	["uuid"]:unknown;
	/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
["uuid_comparison_exp"]: {
	_eq?:ValueTypes["uuid"] | null,
	_gt?:ValueTypes["uuid"] | null,
	_gte?:ValueTypes["uuid"] | null,
	_in?:ValueTypes["uuid"][],
	_is_null?:boolean | null,
	_lt?:ValueTypes["uuid"] | null,
	_lte?:ValueTypes["uuid"] | null,
	_neq?:ValueTypes["uuid"] | null,
	_nin?:ValueTypes["uuid"][]
}
  }

export type ModelTypes = {
    ["Appointment"]: {
		appointmentTypeId?:string,
	date?:string,
	id:string,
	subject?:string,
	subtitle?:string,
	time?:string,
	title?:string,
	videoLink?:string
};
	["AppointmentSlotOutput"]: {
		clinicianAvatar?:string,
	clinicianDisplayTitle:string,
	clinicianSourceId:string,
	startAt:ModelTypes["timestamp"]
};
	["AppointmentSlotsInput"]: GraphQLTypes["AppointmentSlotsInput"];
	["AppointmentTypeV2"]: {
		displayName:string,
	id:string
};
	["AppointmentsFilterTemporalStatus"]: GraphQLTypes["AppointmentsFilterTemporalStatus"];
	["AppointmentsInput"]: GraphQLTypes["AppointmentsInput"];
	["AppointmentsSort"]: GraphQLTypes["AppointmentsSort"];
	["AttributionInput"]: GraphQLTypes["AttributionInput"];
	["CancelAppointmentInput"]: GraphQLTypes["CancelAppointmentInput"];
	["CancelAppointmentOutput"]: {
		success:boolean
};
	["CareTeam"]: {
		avatar?:string,
	fullName:string,
	id:string,
	isCareCoordinator:boolean,
	role:string
};
	["CareTeamMember"]: {
		avatar?:string,
	fullName:string,
	id:string
};
	["CaregiverInput"]: GraphQLTypes["CaregiverInput"];
	["ContactUsInput"]: GraphQLTypes["ContactUsInput"];
	["ContactUsOutput"]: {
		success:boolean
};
	["CreateAppointmentInput"]: GraphQLTypes["CreateAppointmentInput"];
	["CreateAppointmentOutput"]: {
		success:boolean
};
	["CreateSourceMemberTokenInput"]: GraphQLTypes["CreateSourceMemberTokenInput"];
	["CreateSourceMemberTokenOutput"]: {
		token:string
};
	["IsValidDiseaseStateInput"]: GraphQLTypes["IsValidDiseaseStateInput"];
	["IsValidDiseaseStateOutput"]: {
		isValidDiseaseState:boolean
};
	["MarkMessagingThreadAsReadInput"]: GraphQLTypes["MarkMessagingThreadAsReadInput"];
	["MarkMessagingThreadAsReadOutput"]: {
		messagingThreadId:string,
	success:boolean
};
	["MeOutput"]: {
		account_id?:string,
	external_id?:string,
	populations?:string[]
};
	["MemberProfile"]: {
		emailAddress?:string,
	firstName?:string,
	fullName?:string,
	homeAddressCity?:string,
	homeAddressLineOne?:string,
	homeAddressPostalCode?:string,
	homeAddressState?:string,
	id:string,
	lastName?:string,
	phoneNumber?:string,
	preferredName?:string,
	pronouns?:string,
	role?:string,
	shippingAddressCity?:string,
	shippingAddressLineOne?:string,
	shippingAddressPostalCode?:string,
	shippingAddressState?:string
};
	["MessageAttachment"]: GraphQLTypes["MessageAttachment"];
	["MessageThreadMessageAttachment"]: {
		description:string,
	id:string,
	url:string
};
	["Messaging"]: {
		id:string,
	menuLabel:string,
	numberOfUnreadMessages:string
};
	["MessagingThread"]: {
		hasMoreMessages?:boolean,
	hasUnreadMessages?:boolean,
	id:string,
	initialCareTeamMember?:ModelTypes["CareTeamMember"],
	isNewMessagesAllowed?:boolean,
	messageGroups?:(ModelTypes["MessagingThreadMessageGroup"] | undefined)[],
	subject?:string
};
	["MessagingThreadMessage"]: {
		content:string,
	id:string
};
	["MessagingThreadMessageGroup"]: {
		attachments?:(ModelTypes["MessageThreadMessageAttachment"] | undefined)[],
	id:string,
	isSentByYou:boolean,
	messages?:(ModelTypes["MessagingThreadMessage"] | undefined)[],
	senderAvatar?:string,
	senderDisplayName:string,
	sentAt:string
};
	["MessagingThreadSummary"]: {
		associatedMessageThreadId:string,
	content?:string,
	id:string,
	lastSenderAvatar?:string,
	lastSenderDisplayName?:string,
	numberOfUnreadMessages?:string,
	subject:string,
	wasArchivedOn?:string
};
	["OfficeStatus"]: {
		message?:string,
	showBanner:boolean
};
	["PatientInput"]: GraphQLTypes["PatientInput"];
	["PreRegistrationInput"]: GraphQLTypes["PreRegistrationInput"];
	["PreRegistrationOutput"]: {
		email:string,
	firstName:string,
	lastName:string
};
	["RegisterPatientInput"]: GraphQLTypes["RegisterPatientInput"];
	["RegisterPatientOutput"]: {
		external_id?:string,
	source_actor_member_id?:string,
	source_subject_member_id?:string,
	success?:boolean
};
	["RegistrationSlotsInput"]: GraphQLTypes["RegistrationSlotsInput"];
	["RescheduleAppointmentInput"]: GraphQLTypes["RescheduleAppointmentInput"];
	["RescheduleAppointmentOutput"]: {
		success:boolean
};
	["SendMessageInput"]: GraphQLTypes["SendMessageInput"];
	["SendMessageOutput"]: {
		messagingThreadId:string,
	success:boolean
};
	["SignedUrlInput"]: GraphQLTypes["SignedUrlInput"];
	["SignedUrlOutput"]: {
		key:string,
	url:string
};
	["SlackInboundPhonePostInput"]: GraphQLTypes["SlackInboundPhonePostInput"];
	["SlackInboundPhonePostOutput"]: {
		ts:string
};
	["SlackLiveChatMessage"]: {
		text:string,
	ts:string,
	username:string
};
	["SlackLiveChatPollInput"]: GraphQLTypes["SlackLiveChatPollInput"];
	["SlackLiveChatPollOutput"]: {
		messages:ModelTypes["SlackLiveChatMessage"][]
};
	["SlackLiveChatPostInput"]: GraphQLTypes["SlackLiveChatPostInput"];
	["SlackLiveChatPostOutput"]: {
		postUUID:string,
	ts:string
};
	["StateOutput"]: {
		abbreviation?:string,
	capital?:string,
	contiguous?:boolean,
	name?:string,
	territory?:boolean
};
	/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
["String_comparison_exp"]: GraphQLTypes["String_comparison_exp"];
	["TimeZoneOutput"]: {
		abbreviation:string,
	alternativeName?:string,
	continentCode?:string,
	continentName?:string,
	countryCode?:string,
	countryName?:string,
	group?:string,
	mainCities?:string[],
	name?:string,
	rawFormat:string,
	rawOffsetInMinutes:string
};
	["ValidateEmailInput"]: GraphQLTypes["ValidateEmailInput"];
	["ValidateEmailOutput"]: {
		error?:string,
	message?:string
};
	/** mutation root */
["mutation_root"]: {
		cancelAppointment?:ModelTypes["CancelAppointmentOutput"],
	contact_us:ModelTypes["ContactUsOutput"],
	createAppointment?:ModelTypes["CreateAppointmentOutput"],
	createSignedUrl:ModelTypes["SignedUrlOutput"],
	createSourceMemberToken?:ModelTypes["CreateSourceMemberTokenOutput"],
	/** delete data from the table: "synapticure.static_data" */
	delete_synapticure_static_data?:ModelTypes["synapticure_static_data_mutation_response"],
	/** delete single row from the table: "synapticure.static_data" */
	delete_synapticure_static_data_by_pk?:ModelTypes["synapticure_static_data"],
	/** insert data into the table: "synapticure.static_data" */
	insert_synapticure_static_data?:ModelTypes["synapticure_static_data_mutation_response"],
	/** insert a single row into the table: "synapticure.static_data" */
	insert_synapticure_static_data_one?:ModelTypes["synapticure_static_data"],
	markMessagingThreadAsRead?:ModelTypes["MarkMessagingThreadAsReadOutput"],
	registerPatient?:ModelTypes["RegisterPatientOutput"],
	rescheduleAppointment?:ModelTypes["RescheduleAppointmentOutput"],
	sendMessage?:ModelTypes["SendMessageOutput"],
	slackInboundPhonePost:ModelTypes["SlackInboundPhonePostOutput"],
	slackLiveChatPost:ModelTypes["SlackLiveChatPostOutput"],
	/** update data of the table: "synapticure.static_data" */
	update_synapticure_static_data?:ModelTypes["synapticure_static_data_mutation_response"],
	/** update single row of the table: "synapticure.static_data" */
	update_synapticure_static_data_by_pk?:ModelTypes["synapticure_static_data"],
	validateEmail:ModelTypes["ValidateEmailOutput"]
};
	/** column ordering options */
["order_by"]: GraphQLTypes["order_by"];
	["query_root"]: {
		/** Query available appointment types for a member */
	appointmentTypes?:(ModelTypes["AppointmentTypeV2"] | undefined)[],
	appointment_slots?:(ModelTypes["AppointmentSlotOutput"] | undefined)[],
	appointments?:(ModelTypes["Appointment"] | undefined)[],
	careTeam?:(ModelTypes["CareTeam"] | undefined)[],
	isValidDiseaseState?:ModelTypes["IsValidDiseaseStateOutput"],
	me?:ModelTypes["MeOutput"],
	memberProfiles?:(ModelTypes["MemberProfile"] | undefined)[],
	messageThread?:ModelTypes["MessagingThread"],
	messageThreadSummaries?:(ModelTypes["MessagingThreadSummary"] | undefined)[],
	messaging?:ModelTypes["Messaging"],
	officeStatus?:ModelTypes["OfficeStatus"],
	preRegistrationData?:ModelTypes["PreRegistrationOutput"],
	registration_appointment_slots?:(ModelTypes["AppointmentSlotOutput"] | undefined)[],
	slackLiveChatPoll:ModelTypes["SlackLiveChatPollOutput"],
	states:ModelTypes["StateOutput"][],
	/** fetch data from the table: "synapticure.static_data" */
	synapticure_static_data:ModelTypes["synapticure_static_data"][],
	/** fetch aggregated fields from the table: "synapticure.static_data" */
	synapticure_static_data_aggregate:ModelTypes["synapticure_static_data_aggregate"],
	/** fetch data from the table: "synapticure.static_data" using primary key columns */
	synapticure_static_data_by_pk?:ModelTypes["synapticure_static_data"],
	timezones:ModelTypes["TimeZoneOutput"][]
};
	["subscription_root"]: {
		/** fetch data from the table: "synapticure.static_data" */
	synapticure_static_data:ModelTypes["synapticure_static_data"][],
	/** fetch aggregated fields from the table: "synapticure.static_data" */
	synapticure_static_data_aggregate:ModelTypes["synapticure_static_data_aggregate"],
	/** fetch data from the table: "synapticure.static_data" using primary key columns */
	synapticure_static_data_by_pk?:ModelTypes["synapticure_static_data"]
};
	/** Static data and dictionaries */
["synapticure_static_data"]: {
		created_at:ModelTypes["timestamptz"],
	description:string,
	dictionary_type:string,
	key_name:string,
	updated_at:ModelTypes["timestamptz"],
	uuid:ModelTypes["uuid"]
};
	/** aggregated selection of "synapticure.static_data" */
["synapticure_static_data_aggregate"]: {
		aggregate?:ModelTypes["synapticure_static_data_aggregate_fields"],
	nodes:ModelTypes["synapticure_static_data"][]
};
	/** aggregate fields of "synapticure.static_data" */
["synapticure_static_data_aggregate_fields"]: {
		count:number,
	max?:ModelTypes["synapticure_static_data_max_fields"],
	min?:ModelTypes["synapticure_static_data_min_fields"]
};
	/** Boolean expression to filter rows from the table "synapticure.static_data". All fields are combined with a logical 'AND'. */
["synapticure_static_data_bool_exp"]: GraphQLTypes["synapticure_static_data_bool_exp"];
	/** unique or primary key constraints on table "synapticure.static_data" */
["synapticure_static_data_constraint"]: GraphQLTypes["synapticure_static_data_constraint"];
	/** input type for inserting data into table "synapticure.static_data" */
["synapticure_static_data_insert_input"]: GraphQLTypes["synapticure_static_data_insert_input"];
	/** aggregate max on columns */
["synapticure_static_data_max_fields"]: {
		created_at?:ModelTypes["timestamptz"],
	description?:string,
	dictionary_type?:string,
	key_name?:string,
	updated_at?:ModelTypes["timestamptz"],
	uuid?:ModelTypes["uuid"]
};
	/** aggregate min on columns */
["synapticure_static_data_min_fields"]: {
		created_at?:ModelTypes["timestamptz"],
	description?:string,
	dictionary_type?:string,
	key_name?:string,
	updated_at?:ModelTypes["timestamptz"],
	uuid?:ModelTypes["uuid"]
};
	/** response of any mutation on the table "synapticure.static_data" */
["synapticure_static_data_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["synapticure_static_data"][]
};
	/** on_conflict condition type for table "synapticure.static_data" */
["synapticure_static_data_on_conflict"]: GraphQLTypes["synapticure_static_data_on_conflict"];
	/** Ordering options when selecting data from "synapticure.static_data". */
["synapticure_static_data_order_by"]: GraphQLTypes["synapticure_static_data_order_by"];
	/** primary key columns input for table: synapticure_static_data */
["synapticure_static_data_pk_columns_input"]: GraphQLTypes["synapticure_static_data_pk_columns_input"];
	/** select columns of table "synapticure.static_data" */
["synapticure_static_data_select_column"]: GraphQLTypes["synapticure_static_data_select_column"];
	/** input type for updating data in table "synapticure.static_data" */
["synapticure_static_data_set_input"]: GraphQLTypes["synapticure_static_data_set_input"];
	/** update columns of table "synapticure.static_data" */
["synapticure_static_data_update_column"]: GraphQLTypes["synapticure_static_data_update_column"];
	["timestamp"]:any;
	["timestamptz"]:any;
	/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
["timestamptz_comparison_exp"]: GraphQLTypes["timestamptz_comparison_exp"];
	["uuid"]:any;
	/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
["uuid_comparison_exp"]: GraphQLTypes["uuid_comparison_exp"]
    }

export type GraphQLTypes = {
    ["Appointment"]: {
	__typename: "Appointment",
	appointmentTypeId?: string,
	date?: string,
	id: string,
	subject?: string,
	subtitle?: string,
	time?: string,
	title?: string,
	videoLink?: string
};
	["AppointmentSlotOutput"]: {
	__typename: "AppointmentSlotOutput",
	clinicianAvatar?: string,
	clinicianDisplayTitle: string,
	clinicianSourceId: string,
	startAt: GraphQLTypes["timestamp"]
};
	["AppointmentSlotsInput"]: {
		appointment_type: string
};
	["AppointmentTypeV2"]: {
	__typename: "AppointmentTypeV2",
	displayName: string,
	id: string
};
	["AppointmentsFilterTemporalStatus"]: AppointmentsFilterTemporalStatus;
	["AppointmentsInput"]: {
		after?: string,
	limit?: number,
	sort?: GraphQLTypes["AppointmentsSort"],
	temporalStatus?: Array<GraphQLTypes["AppointmentsFilterTemporalStatus"]>
};
	["AppointmentsSort"]: AppointmentsSort;
	["AttributionInput"]: {
		adgroup?: string,
	campaign?: string,
	content?: string,
	medium?: string,
	source?: string,
	term?: string
};
	["CancelAppointmentInput"]: {
		appointmentId: string
};
	["CancelAppointmentOutput"]: {
	__typename: "CancelAppointmentOutput",
	success: boolean
};
	["CareTeam"]: {
	__typename: "CareTeam",
	avatar?: string,
	fullName: string,
	id: string,
	isCareCoordinator: boolean,
	role: string
};
	["CareTeamMember"]: {
	__typename: "CareTeamMember",
	avatar?: string,
	fullName: string,
	id: string
};
	["CaregiverInput"]: {
		birth_date?: GraphQLTypes["timestamptz"],
	birth_sex?: string,
	email?: string,
	first_name?: string,
	id?: GraphQLTypes["uuid"],
	last_name?: string,
	phone_number?: string,
	relationship?: GraphQLTypes["uuid"],
	state?: string,
	zip_code?: string
};
	["ContactUsInput"]: {
		email: string,
	question: string
};
	["ContactUsOutput"]: {
	__typename: "ContactUsOutput",
	success: boolean
};
	["CreateAppointmentInput"]: {
		appointmentType: string,
	participants: Array<string>,
	startAt: string,
	subject?: string,
	timezone: string
};
	["CreateAppointmentOutput"]: {
	__typename: "CreateAppointmentOutput",
	success: boolean
};
	["CreateSourceMemberTokenInput"]: {
		actor_member_id: string,
	subject_member_id: string
};
	["CreateSourceMemberTokenOutput"]: {
	__typename: "CreateSourceMemberTokenOutput",
	token: string
};
	["IsValidDiseaseStateInput"]: {
		diagnosis: string,
	state: string
};
	["IsValidDiseaseStateOutput"]: {
	__typename: "IsValidDiseaseStateOutput",
	isValidDiseaseState: boolean
};
	["MarkMessagingThreadAsReadInput"]: {
		messagingThreadId: string
};
	["MarkMessagingThreadAsReadOutput"]: {
	__typename: "MarkMessagingThreadAsReadOutput",
	messagingThreadId: string,
	success: boolean
};
	["MeOutput"]: {
	__typename: "MeOutput",
	account_id?: string,
	external_id?: string,
	populations?: Array<string>
};
	["MemberProfile"]: {
	__typename: "MemberProfile",
	emailAddress?: string,
	firstName?: string,
	fullName?: string,
	homeAddressCity?: string,
	homeAddressLineOne?: string,
	homeAddressPostalCode?: string,
	homeAddressState?: string,
	id: string,
	lastName?: string,
	phoneNumber?: string,
	preferredName?: string,
	pronouns?: string,
	role?: string,
	shippingAddressCity?: string,
	shippingAddressLineOne?: string,
	shippingAddressPostalCode?: string,
	shippingAddressState?: string
};
	["MessageAttachment"]: {
		key: string,
	name: string
};
	["MessageThreadMessageAttachment"]: {
	__typename: "MessageThreadMessageAttachment",
	description: string,
	id: string,
	url: string
};
	["Messaging"]: {
	__typename: "Messaging",
	id: string,
	menuLabel: string,
	numberOfUnreadMessages: string
};
	["MessagingThread"]: {
	__typename: "MessagingThread",
	hasMoreMessages?: boolean,
	hasUnreadMessages?: boolean,
	id: string,
	initialCareTeamMember?: GraphQLTypes["CareTeamMember"],
	isNewMessagesAllowed?: boolean,
	messageGroups?: Array<GraphQLTypes["MessagingThreadMessageGroup"] | undefined>,
	subject?: string
};
	["MessagingThreadMessage"]: {
	__typename: "MessagingThreadMessage",
	content: string,
	id: string
};
	["MessagingThreadMessageGroup"]: {
	__typename: "MessagingThreadMessageGroup",
	attachments?: Array<GraphQLTypes["MessageThreadMessageAttachment"] | undefined>,
	id: string,
	isSentByYou: boolean,
	messages?: Array<GraphQLTypes["MessagingThreadMessage"] | undefined>,
	senderAvatar?: string,
	senderDisplayName: string,
	sentAt: string
};
	["MessagingThreadSummary"]: {
	__typename: "MessagingThreadSummary",
	associatedMessageThreadId: string,
	content?: string,
	id: string,
	lastSenderAvatar?: string,
	lastSenderDisplayName?: string,
	numberOfUnreadMessages?: string,
	subject: string,
	wasArchivedOn?: string
};
	["OfficeStatus"]: {
	__typename: "OfficeStatus",
	message?: string,
	showBanner: boolean
};
	["PatientInput"]: {
		birth_date?: GraphQLTypes["timestamptz"],
	birth_sex?: string,
	diagnosis?: string,
	email?: string,
	first_name?: string,
	has_confirmed_diagnosis?: boolean,
	id?: GraphQLTypes["uuid"],
	last_name?: string,
	phone_number?: string,
	relationship?: string,
	state?: string,
	zip_code?: string
};
	["PreRegistrationInput"]: {
		secret: string
};
	["PreRegistrationOutput"]: {
	__typename: "PreRegistrationOutput",
	email: string,
	firstName: string,
	lastName: string
};
	["RegisterPatientInput"]: {
		appointment_participant?: string,
	appointment_type?: string,
	attribution?: GraphQLTypes["AttributionInput"],
	call_type?: string,
	caregiver?: GraphQLTypes["CaregiverInput"],
	patient: GraphQLTypes["PatientInput"],
	pre_registration_secret?: string,
	scheduled_at?: GraphQLTypes["timestamp"],
	timezone?: string,
	wants_email_schedule?: boolean
};
	["RegisterPatientOutput"]: {
	__typename: "RegisterPatientOutput",
	external_id?: string,
	source_actor_member_id?: string,
	source_subject_member_id?: string,
	success?: boolean
};
	["RegistrationSlotsInput"]: {
		appointment_type_key: string,
	state: string
};
	["RescheduleAppointmentInput"]: {
		appointmentId: string,
	participants: Array<string>,
	startAt: string,
	subject: string
};
	["RescheduleAppointmentOutput"]: {
	__typename: "RescheduleAppointmentOutput",
	success: boolean
};
	["SendMessageInput"]: {
		attachments?: Array<GraphQLTypes["MessageAttachment"] | undefined>,
	careTeamMemberId?: string,
	content: string,
	messagingThreadId?: string
};
	["SendMessageOutput"]: {
	__typename: "SendMessageOutput",
	messagingThreadId: string,
	success: boolean
};
	["SignedUrlInput"]: {
		fileName: string,
	fileType: string
};
	["SignedUrlOutput"]: {
	__typename: "SignedUrlOutput",
	key: string,
	url: string
};
	["SlackInboundPhonePostInput"]: {
		_cioanonid?: string,
	_cioid?: string,
	currentURL: string,
	mixpanel?: string,
	phoneNumber: string,
	ts?: string
};
	["SlackInboundPhonePostOutput"]: {
	__typename: "SlackInboundPhonePostOutput",
	ts: string
};
	["SlackLiveChatMessage"]: {
	__typename: "SlackLiveChatMessage",
	text: string,
	ts: string,
	username: string
};
	["SlackLiveChatPollInput"]: {
		latest_ts: string,
	postUUID: string,
	ts: string
};
	["SlackLiveChatPollOutput"]: {
	__typename: "SlackLiveChatPollOutput",
	messages: Array<GraphQLTypes["SlackLiveChatMessage"]>
};
	["SlackLiveChatPostInput"]: {
		_cioanonid?: string,
	_cioid?: string,
	currentURL: string,
	message: string,
	mixpanel?: string,
	postUUID?: string,
	ts?: string
};
	["SlackLiveChatPostOutput"]: {
	__typename: "SlackLiveChatPostOutput",
	postUUID: string,
	ts: string
};
	["StateOutput"]: {
	__typename: "StateOutput",
	abbreviation?: string,
	capital?: string,
	contiguous?: boolean,
	name?: string,
	territory?: boolean
};
	/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
["String_comparison_exp"]: {
		_eq?: string,
	_gt?: string,
	_gte?: string,
	/** does the column match the given case-insensitive pattern */
	_ilike?: string,
	_in?: Array<string>,
	/** does the column match the given POSIX regular expression, case insensitive */
	_iregex?: string,
	_is_null?: boolean,
	/** does the column match the given pattern */
	_like?: string,
	_lt?: string,
	_lte?: string,
	_neq?: string,
	/** does the column NOT match the given case-insensitive pattern */
	_nilike?: string,
	_nin?: Array<string>,
	/** does the column NOT match the given POSIX regular expression, case insensitive */
	_niregex?: string,
	/** does the column NOT match the given pattern */
	_nlike?: string,
	/** does the column NOT match the given POSIX regular expression, case sensitive */
	_nregex?: string,
	/** does the column NOT match the given SQL regular expression */
	_nsimilar?: string,
	/** does the column match the given POSIX regular expression, case sensitive */
	_regex?: string,
	/** does the column match the given SQL regular expression */
	_similar?: string
};
	["TimeZoneOutput"]: {
	__typename: "TimeZoneOutput",
	abbreviation: string,
	alternativeName?: string,
	continentCode?: string,
	continentName?: string,
	countryCode?: string,
	countryName?: string,
	group?: string,
	mainCities?: Array<string>,
	name?: string,
	rawFormat: string,
	rawOffsetInMinutes: string
};
	["ValidateEmailInput"]: {
		careRecipientEmail?: string,
	email: string
};
	["ValidateEmailOutput"]: {
	__typename: "ValidateEmailOutput",
	error?: string,
	message?: string
};
	/** mutation root */
["mutation_root"]: {
	__typename: "mutation_root",
	cancelAppointment?: GraphQLTypes["CancelAppointmentOutput"],
	contact_us: GraphQLTypes["ContactUsOutput"],
	createAppointment?: GraphQLTypes["CreateAppointmentOutput"],
	createSignedUrl: GraphQLTypes["SignedUrlOutput"],
	createSourceMemberToken?: GraphQLTypes["CreateSourceMemberTokenOutput"],
	/** delete data from the table: "synapticure.static_data" */
	delete_synapticure_static_data?: GraphQLTypes["synapticure_static_data_mutation_response"],
	/** delete single row from the table: "synapticure.static_data" */
	delete_synapticure_static_data_by_pk?: GraphQLTypes["synapticure_static_data"],
	/** insert data into the table: "synapticure.static_data" */
	insert_synapticure_static_data?: GraphQLTypes["synapticure_static_data_mutation_response"],
	/** insert a single row into the table: "synapticure.static_data" */
	insert_synapticure_static_data_one?: GraphQLTypes["synapticure_static_data"],
	markMessagingThreadAsRead?: GraphQLTypes["MarkMessagingThreadAsReadOutput"],
	registerPatient?: GraphQLTypes["RegisterPatientOutput"],
	rescheduleAppointment?: GraphQLTypes["RescheduleAppointmentOutput"],
	sendMessage?: GraphQLTypes["SendMessageOutput"],
	slackInboundPhonePost: GraphQLTypes["SlackInboundPhonePostOutput"],
	slackLiveChatPost: GraphQLTypes["SlackLiveChatPostOutput"],
	/** update data of the table: "synapticure.static_data" */
	update_synapticure_static_data?: GraphQLTypes["synapticure_static_data_mutation_response"],
	/** update single row of the table: "synapticure.static_data" */
	update_synapticure_static_data_by_pk?: GraphQLTypes["synapticure_static_data"],
	validateEmail: GraphQLTypes["ValidateEmailOutput"]
};
	/** column ordering options */
["order_by"]: order_by;
	["query_root"]: {
	__typename: "query_root",
	/** Query available appointment types for a member */
	appointmentTypes?: Array<GraphQLTypes["AppointmentTypeV2"] | undefined>,
	appointment_slots?: Array<GraphQLTypes["AppointmentSlotOutput"] | undefined>,
	appointments?: Array<GraphQLTypes["Appointment"] | undefined>,
	careTeam?: Array<GraphQLTypes["CareTeam"] | undefined>,
	isValidDiseaseState?: GraphQLTypes["IsValidDiseaseStateOutput"],
	me?: GraphQLTypes["MeOutput"],
	memberProfiles?: Array<GraphQLTypes["MemberProfile"] | undefined>,
	messageThread?: GraphQLTypes["MessagingThread"],
	messageThreadSummaries?: Array<GraphQLTypes["MessagingThreadSummary"] | undefined>,
	messaging?: GraphQLTypes["Messaging"],
	officeStatus?:GraphQLTypes["OfficeStatus"],
	preRegistrationData?: GraphQLTypes["PreRegistrationOutput"],
	registration_appointment_slots?: Array<GraphQLTypes["AppointmentSlotOutput"] | undefined>,
	slackLiveChatPoll: GraphQLTypes["SlackLiveChatPollOutput"],
	states: Array<GraphQLTypes["StateOutput"]>,
	/** fetch data from the table: "synapticure.static_data" */
	synapticure_static_data: Array<GraphQLTypes["synapticure_static_data"]>,
	/** fetch aggregated fields from the table: "synapticure.static_data" */
	synapticure_static_data_aggregate: GraphQLTypes["synapticure_static_data_aggregate"],
	/** fetch data from the table: "synapticure.static_data" using primary key columns */
	synapticure_static_data_by_pk?: GraphQLTypes["synapticure_static_data"],
	timezones: Array<GraphQLTypes["TimeZoneOutput"]>
};
	["subscription_root"]: {
	__typename: "subscription_root",
	/** fetch data from the table: "synapticure.static_data" */
	synapticure_static_data: Array<GraphQLTypes["synapticure_static_data"]>,
	/** fetch aggregated fields from the table: "synapticure.static_data" */
	synapticure_static_data_aggregate: GraphQLTypes["synapticure_static_data_aggregate"],
	/** fetch data from the table: "synapticure.static_data" using primary key columns */
	synapticure_static_data_by_pk?: GraphQLTypes["synapticure_static_data"]
};
	/** Static data and dictionaries */
["synapticure_static_data"]: {
	__typename: "synapticure_static_data",
	created_at: GraphQLTypes["timestamptz"],
	description: string,
	dictionary_type: string,
	key_name: string,
	updated_at: GraphQLTypes["timestamptz"],
	uuid: GraphQLTypes["uuid"]
};
	/** aggregated selection of "synapticure.static_data" */
["synapticure_static_data_aggregate"]: {
	__typename: "synapticure_static_data_aggregate",
	aggregate?: GraphQLTypes["synapticure_static_data_aggregate_fields"],
	nodes: Array<GraphQLTypes["synapticure_static_data"]>
};
	/** aggregate fields of "synapticure.static_data" */
["synapticure_static_data_aggregate_fields"]: {
	__typename: "synapticure_static_data_aggregate_fields",
	count: number,
	max?: GraphQLTypes["synapticure_static_data_max_fields"],
	min?: GraphQLTypes["synapticure_static_data_min_fields"]
};
	/** Boolean expression to filter rows from the table "synapticure.static_data". All fields are combined with a logical 'AND'. */
["synapticure_static_data_bool_exp"]: {
		_and?: Array<GraphQLTypes["synapticure_static_data_bool_exp"]>,
	_not?: GraphQLTypes["synapticure_static_data_bool_exp"],
	_or?: Array<GraphQLTypes["synapticure_static_data_bool_exp"]>,
	created_at?: GraphQLTypes["timestamptz_comparison_exp"],
	description?: GraphQLTypes["String_comparison_exp"],
	dictionary_type?: GraphQLTypes["String_comparison_exp"],
	key_name?: GraphQLTypes["String_comparison_exp"],
	updated_at?: GraphQLTypes["timestamptz_comparison_exp"],
	uuid?: GraphQLTypes["uuid_comparison_exp"]
};
	/** unique or primary key constraints on table "synapticure.static_data" */
["synapticure_static_data_constraint"]: synapticure_static_data_constraint;
	/** input type for inserting data into table "synapticure.static_data" */
["synapticure_static_data_insert_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	description?: string,
	dictionary_type?: string,
	key_name?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	uuid?: GraphQLTypes["uuid"]
};
	/** aggregate max on columns */
["synapticure_static_data_max_fields"]: {
	__typename: "synapticure_static_data_max_fields",
	created_at?: GraphQLTypes["timestamptz"],
	description?: string,
	dictionary_type?: string,
	key_name?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	uuid?: GraphQLTypes["uuid"]
};
	/** aggregate min on columns */
["synapticure_static_data_min_fields"]: {
	__typename: "synapticure_static_data_min_fields",
	created_at?: GraphQLTypes["timestamptz"],
	description?: string,
	dictionary_type?: string,
	key_name?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	uuid?: GraphQLTypes["uuid"]
};
	/** response of any mutation on the table "synapticure.static_data" */
["synapticure_static_data_mutation_response"]: {
	__typename: "synapticure_static_data_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["synapticure_static_data"]>
};
	/** on_conflict condition type for table "synapticure.static_data" */
["synapticure_static_data_on_conflict"]: {
		constraint: GraphQLTypes["synapticure_static_data_constraint"],
	update_columns: Array<GraphQLTypes["synapticure_static_data_update_column"]>,
	where?: GraphQLTypes["synapticure_static_data_bool_exp"]
};
	/** Ordering options when selecting data from "synapticure.static_data". */
["synapticure_static_data_order_by"]: {
		created_at?: GraphQLTypes["order_by"],
	description?: GraphQLTypes["order_by"],
	dictionary_type?: GraphQLTypes["order_by"],
	key_name?: GraphQLTypes["order_by"],
	updated_at?: GraphQLTypes["order_by"],
	uuid?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: synapticure_static_data */
["synapticure_static_data_pk_columns_input"]: {
		uuid: GraphQLTypes["uuid"]
};
	/** select columns of table "synapticure.static_data" */
["synapticure_static_data_select_column"]: synapticure_static_data_select_column;
	/** input type for updating data in table "synapticure.static_data" */
["synapticure_static_data_set_input"]: {
		created_at?: GraphQLTypes["timestamptz"],
	description?: string,
	dictionary_type?: string,
	key_name?: string,
	updated_at?: GraphQLTypes["timestamptz"],
	uuid?: GraphQLTypes["uuid"]
};
	/** update columns of table "synapticure.static_data" */
["synapticure_static_data_update_column"]: synapticure_static_data_update_column;
	["timestamp"]:any;
	["timestamptz"]:any;
	/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
["timestamptz_comparison_exp"]: {
		_eq?: GraphQLTypes["timestamptz"],
	_gt?: GraphQLTypes["timestamptz"],
	_gte?: GraphQLTypes["timestamptz"],
	_in?: Array<GraphQLTypes["timestamptz"]>,
	_is_null?: boolean,
	_lt?: GraphQLTypes["timestamptz"],
	_lte?: GraphQLTypes["timestamptz"],
	_neq?: GraphQLTypes["timestamptz"],
	_nin?: Array<GraphQLTypes["timestamptz"]>
};
	["uuid"]:any;
	/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
["uuid_comparison_exp"]: {
		_eq?: GraphQLTypes["uuid"],
	_gt?: GraphQLTypes["uuid"],
	_gte?: GraphQLTypes["uuid"],
	_in?: Array<GraphQLTypes["uuid"]>,
	_is_null?: boolean,
	_lt?: GraphQLTypes["uuid"],
	_lte?: GraphQLTypes["uuid"],
	_neq?: GraphQLTypes["uuid"],
	_nin?: Array<GraphQLTypes["uuid"]>
}
    }
export const enum AppointmentsFilterTemporalStatus {
	NOW = "NOW",
	PAST = "PAST",
	UPCOMING = "UPCOMING"
}
export const enum AppointmentsSort {
	START_AT = "START_AT"
}
/** column ordering options */
export const enum order_by {
	asc = "asc",
	asc_nulls_first = "asc_nulls_first",
	asc_nulls_last = "asc_nulls_last",
	desc = "desc",
	desc_nulls_first = "desc_nulls_first",
	desc_nulls_last = "desc_nulls_last"
}
/** unique or primary key constraints on table "synapticure.static_data" */
export const enum synapticure_static_data_constraint {
	static_data_pkey = "static_data_pkey"
}
/** select columns of table "synapticure.static_data" */
export const enum synapticure_static_data_select_column {
	created_at = "created_at",
	description = "description",
	dictionary_type = "dictionary_type",
	key_name = "key_name",
	updated_at = "updated_at",
	uuid = "uuid"
}
/** update columns of table "synapticure.static_data" */
export const enum synapticure_static_data_update_column {
	created_at = "created_at",
	description = "description",
	dictionary_type = "dictionary_type",
	key_name = "key_name",
	updated_at = "updated_at",
	uuid = "uuid"
}
export class GraphQLError extends Error {
    constructor(public response: GraphQLResponse) {
      super("");
      console.error(response);
    }
    toString() {
      return "GraphQL Response Error";
    }
  }


export type UnwrapPromise<T> = T extends Promise<infer R> ? R : T;
export type ZeusState<T extends (...args: any[]) => Promise<any>> = NonNullable<
  UnwrapPromise<ReturnType<T>>
>;
export type ZeusHook<
  T extends (
    ...args: any[]
  ) => Record<string, (...args: any[]) => Promise<any>>,
  N extends keyof ReturnType<T>
> = ZeusState<ReturnType<T>[N]>;

type WithTypeNameValue<T> = T & {
  __typename?: true;
};
type AliasType<T> = WithTypeNameValue<T> & {
  __alias?: Record<string, WithTypeNameValue<T>>;
};
export interface GraphQLResponse {
  data?: Record<string, any>;
  errors?: Array<{
    message: string;
  }>;
}
type DeepAnify<T> = {
  [P in keyof T]?: any;
};
type IsPayLoad<T> = T extends [any, infer PayLoad] ? PayLoad : T;
type IsArray<T, U> = T extends Array<infer R> ? InputType<R, U>[] : InputType<T, U>;
type FlattenArray<T> = T extends Array<infer R> ? R : T;

type IsInterfaced<SRC extends DeepAnify<DST>, DST> = FlattenArray<SRC> extends ZEUS_INTERFACES | ZEUS_UNIONS
  ? {
      [P in keyof SRC]: SRC[P] extends '__union' & infer R
        ? P extends keyof DST
          ? IsArray<R, '__typename' extends keyof DST ? DST[P] & { __typename: true } : DST[P]>
          : {}
        : never;
    }[keyof DST] &
      {
        [P in keyof Omit<
          Pick<
            SRC,
            {
              [P in keyof DST]: SRC[P] extends '__union' & infer R ? never : P;
            }[keyof DST]
          >,
          '__typename'
        >]: IsPayLoad<DST[P]> extends true ? SRC[P] : IsArray<SRC[P], DST[P]>;
      }
  : {
      [P in keyof Pick<SRC, keyof DST>]: IsPayLoad<DST[P]> extends true ? SRC[P] : IsArray<SRC[P], DST[P]>;
    };

export type MapType<SRC, DST> = SRC extends DeepAnify<DST> ? IsInterfaced<SRC, DST> : never;
export type InputType<SRC, DST> = IsPayLoad<DST> extends { __alias: infer R }
  ? {
      [P in keyof R]: MapType<SRC, R[P]>;
    } &
      MapType<SRC, Omit<IsPayLoad<DST>, '__alias'>>
  : MapType<SRC, IsPayLoad<DST>>;
type Func<P extends any[], R> = (...args: P) => R;
type AnyFunc = Func<any, any>;
export type ArgsType<F extends AnyFunc> = F extends Func<infer P, any> ? P : never;
export type OperationOptions = {
  variables?: Record<string, any>;
  operationName?: string;
};
export type OperationToGraphQL<V, T> = <Z extends V>(o: Z | V, options?: OperationOptions) => Promise<InputType<T, Z>>;
export type SubscriptionToGraphQL<V, T> = <Z extends V>(
  o: Z | V,
  options?: OperationOptions,
) => {
  ws: WebSocket;
  on: (fn: (args: InputType<T, Z>) => void) => void;
  off: (fn: (e: { data?: InputType<T, Z>; code?: number; reason?: string; message?: string }) => void) => void;
  error: (fn: (e: { data?: InputType<T, Z>; errors?:string[] }) => void) => void;
  open: () => void;
};
export type SelectionFunction<V> = <T>(t: T | V) => T;
export type fetchOptions = ArgsType<typeof fetch>;
type websocketOptions = typeof WebSocket extends new (
  ...args: infer R
) => WebSocket
  ? R
  : never;
export type chainOptions =
  | [fetchOptions[0], fetchOptions[1] & {websocket?: websocketOptions}]
  | [fetchOptions[0]];
export type FetchFunction = (
  query: string,
  variables?: Record<string, any>,
) => Promise<any>;
export type SubscriptionFunction = (query: string) => void;
type NotUndefined<T> = T extends undefined ? never : T;
export type ResolverType<F> = NotUndefined<F extends [infer ARGS, any] ? ARGS : undefined>;



export const ZeusSelect = <T>() => ((t: any) => t) as SelectionFunction<T>;

export const ScalarResolver = (scalar: string, value: any) => {
  switch (scalar) {
    case 'String':
      return  `${JSON.stringify(value)}`;
    case 'Int':
      return `${value}`;
    case 'Float':
      return `${value}`;
    case 'Boolean':
      return `${value}`;
    case 'ID':
      return `"${value}"`;
    case 'enum':
      return `${value}`;
    case 'scalar':
      return `${value}`;
    default:
      return false;
  }
};


export const TypesPropsResolver = ({
    value,
    type,
    name,
    key,
    blockArrays
}: {
    value: any;
    type: string;
    name: string;
    key?: string;
    blockArrays?: boolean;
}): string => {
    if (value === null) {
        return `null`;
    }
    let resolvedValue = AllTypesProps[type][name];
    if (key) {
        resolvedValue = resolvedValue[key];
    }
    if (!resolvedValue) {
        throw new Error(`Cannot resolve ${type} ${name}${key ? ` ${key}` : ''}`)
    }
    const typeResolved = resolvedValue.type;
    const isArray = resolvedValue.array;
    const isArrayRequired = resolvedValue.arrayRequired;
    if (typeof value === 'string' && value.startsWith(`ZEUS_VAR$`)) {
        const isRequired = resolvedValue.required ? '!' : '';
        let t = `${typeResolved}`;
        if (isArray) {
          if (isRequired) {
              t = `${t}!`;
          }
          t = `[${t}]`;
          if(isArrayRequired){
            t = `${t}!`;
          }
        }else{
          if (isRequired) {
                t = `${t}!`;
          }
        }
        return `\$${value.split(`ZEUS_VAR$`)[1]}__ZEUS_VAR__${t}`;
    }
    if (isArray && !blockArrays) {
        return `[${value
        .map((v: any) => TypesPropsResolver({ value: v, type, name, key, blockArrays: true }))
        .join(',')}]`;
    }
    const reslovedScalar = ScalarResolver(typeResolved, value);
    if (!reslovedScalar) {
        const resolvedType = AllTypesProps[typeResolved];
        if (typeof resolvedType === 'object') {
        const argsKeys = Object.keys(resolvedType);
        return `{${argsKeys
            .filter((ak) => value[ak] !== undefined)
            .map(
            (ak) => `${ak}:${TypesPropsResolver({ value: value[ak], type: typeResolved, name: ak })}`
            )}}`;
        }
        return ScalarResolver(AllTypesProps[typeResolved], value) as string;
    }
    return reslovedScalar;
};


const isArrayFunction = (
  parent: string[],
  a: any[]
) => {
  const [values, r] = a;
  const [mainKey, key, ...keys] = parent;
  const keyValues = Object.keys(values).filter((k) => typeof values[k] !== 'undefined');

  if (!keys.length) {
      return keyValues.length > 0
        ? `(${keyValues
            .map(
              (v) =>
                `${v}:${TypesPropsResolver({
                  value: values[v],
                  type: mainKey,
                  name: key,
                  key: v
                })}`
            )
            .join(',')})${r ? traverseToSeekArrays(parent, r) : ''}`
        : traverseToSeekArrays(parent, r);
    }

  const [typeResolverKey] = keys.splice(keys.length - 1, 1);
  let valueToResolve = ReturnTypes[mainKey][key];
  for (const k of keys) {
    valueToResolve = ReturnTypes[valueToResolve][k];
  }

  const argumentString =
    keyValues.length > 0
      ? `(${keyValues
          .map(
            (v) =>
              `${v}:${TypesPropsResolver({
                value: values[v],
                type: valueToResolve,
                name: typeResolverKey,
                key: v
              })}`
          )
          .join(',')})${r ? traverseToSeekArrays(parent, r) : ''}`
      : traverseToSeekArrays(parent, r);
  return argumentString;
};


const resolveKV = (k: string, v: boolean | string | { [x: string]: boolean | string }) =>
  typeof v === 'boolean' ? k : typeof v === 'object' ? `${k}{${objectToTree(v)}}` : `${k}${v}`;


const objectToTree = (o: { [x: string]: boolean | string }): string =>
  `{${Object.keys(o).map((k) => `${resolveKV(k, o[k])}`).join(' ')}}`;


const traverseToSeekArrays = (parent: string[], a?: any): string => {
  if (!a) return '';
  if (Object.keys(a).length === 0) {
    return '';
  }
  let b: Record<string, any> = {};
  if (Array.isArray(a)) {
    return isArrayFunction([...parent], a);
  } else {
    if (typeof a === 'object') {
      Object.keys(a)
        .filter((k) => typeof a[k] !== 'undefined')
        .forEach((k) => {
        if (k === '__alias') {
          Object.keys(a[k]).forEach((aliasKey) => {
            const aliasOperations = a[k][aliasKey];
            const aliasOperationName = Object.keys(aliasOperations)[0];
            const aliasOperation = aliasOperations[aliasOperationName];
            b[
              `${aliasOperationName}__alias__${aliasKey}: ${aliasOperationName}`
            ] = traverseToSeekArrays([...parent, aliasOperationName], aliasOperation);
          });
        } else {
          b[k] = traverseToSeekArrays([...parent, k], a[k]);
        }
      });
    } else {
      return '';
    }
  }
  return objectToTree(b);
};  


const buildQuery = (type: string, a?: Record<any, any>) => 
  traverseToSeekArrays([type], a);


const inspectVariables = (query: string) => {
  const regex = /\$\b\w*__ZEUS_VAR__\[?[^!^\]^\s^,^\)^\}]*[!]?[\]]?[!]?/g;
  let result;
  const AllVariables: string[] = [];
  while ((result = regex.exec(query))) {
    if (AllVariables.includes(result[0])) {
      continue;
    }
    AllVariables.push(result[0]);
  }
  if (!AllVariables.length) {
    return query;
  }
  let filteredQuery = query;
  AllVariables.forEach((variable) => {
    while (filteredQuery.includes(variable)) {
      filteredQuery = filteredQuery.replace(variable, variable.split('__ZEUS_VAR__')[0]);
    }
  });
  return `(${AllVariables.map((a) => a.split('__ZEUS_VAR__'))
    .map(([variableName, variableType]) => `${variableName}:${variableType}`)
    .join(', ')})${filteredQuery}`;
};


export const queryConstruct = (t: 'query' | 'mutation' | 'subscription', tName: string, operationName?: string) => (o: Record<any, any>) =>
  `${t.toLowerCase()}${operationName ? ' ' + operationName : ''}${inspectVariables(buildQuery(tName, o))}`;
  

const fullChainConstruct = (fn: FetchFunction) => (t: 'query' | 'mutation' | 'subscription', tName: string) => (
  o: Record<any, any>,
  options?: OperationOptions,
) => fn(queryConstruct(t, tName, options?.operationName)(o), options?.variables).then((r:any) => { 
  seekForAliases(r)
  return r
});

export const fullChainConstructor = <F extends FetchFunction, R extends keyof ValueTypes>(
  fn: F,
  operation: 'query' | 'mutation' | 'subscription',
  key: R,
) =>
  ((o, options) => fullChainConstruct(fn)(operation, key)(o as any, options)) as OperationToGraphQL<
    ValueTypes[R],
    GraphQLTypes[R]
  >;


const fullSubscriptionConstruct = (fn: SubscriptionFunction) => (
  t: 'query' | 'mutation' | 'subscription',
  tName: string,
) => (o: Record<any, any>, options?: OperationOptions) =>
  fn(queryConstruct(t, tName, options?.operationName)(o));

export const fullSubscriptionConstructor = <F extends SubscriptionFunction, R extends keyof ValueTypes>(
  fn: F,
  operation: 'query' | 'mutation' | 'subscription',
  key: R,
) =>
  ((o, options) => fullSubscriptionConstruct(fn)(operation, key)(o as any, options)) as SubscriptionToGraphQL<
    ValueTypes[R],
    GraphQLTypes[R]
  >;


const seekForAliases = (response: any) => {
  const traverseAlias = (value: any) => {
    if (Array.isArray(value)) {
      value.forEach(seekForAliases);
    } else {
      if (typeof value === 'object') {
        seekForAliases(value);
      }
    }
  };
  if (typeof response === 'object' && response) {
    const keys = Object.keys(response);
    if (keys.length < 1) {
      return;
    }
    keys.forEach((k) => {
      const value = response[k];
      if (k.indexOf('__alias__') !== -1) {
        const [operation, alias] = k.split('__alias__');
        response[alias] = {
          [operation]: value,
        };
        delete response[k];
      }
      traverseAlias(value);
    });
  }
};


export const $ = (t: TemplateStringsArray): any => `ZEUS_VAR$${t.join('')}`;


export const resolverFor = <
  X,
  T extends keyof ValueTypes,
  Z extends keyof ValueTypes[T],
>(
  type: T,
  field: Z,
  fn: (
    args: Required<ValueTypes[T]>[Z] extends [infer Input, any] ? Input : any,
    source: any,
  ) => Z extends keyof ModelTypes[T] ? ModelTypes[T][Z] | Promise<ModelTypes[T][Z]> | X : any,
) => fn as (args?: any,source?: any) => any;


const handleFetchResponse = (
  response: Parameters<Extract<Parameters<ReturnType<typeof fetch>['then']>[0], Function>>[0]
): Promise<GraphQLResponse> => {
  if (!response.ok) {
    return new Promise((_, reject) => {
      response.text().then(text => {
        try { reject(JSON.parse(text)); }
        catch (err) { reject(text); }
      }).catch(reject);
    });
  }
  return response.json();
};

export const apiFetch = (options: fetchOptions) => (query: string, variables: Record<string, any> = {}) => {
    let fetchFunction = fetch;
    let queryString = query;
    let fetchOptions = options[1] || {};
    if (fetchOptions.method && fetchOptions.method === 'GET') {
      queryString = encodeURIComponent(query);
      return fetchFunction(`${options[0]}?query=${queryString}`, fetchOptions)
        .then(handleFetchResponse)
        .then((response: GraphQLResponse) => {
          if (response.errors) {
            throw new GraphQLError(response);
          }
          return response.data;
        });
    }
    return fetchFunction(`${options[0]}`, {
      body: JSON.stringify({ query: queryString, variables }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      ...fetchOptions
    })
      .then(handleFetchResponse)
      .then((response: GraphQLResponse) => {
        if (response.errors) {
          throw new GraphQLError(response);
        }
        return response.data;
      });
  };
  

export const apiSubscription = (options: chainOptions) => (
    query: string,
  ) => {
    try {
      const queryString = options[0] + '?query=' + encodeURIComponent(query);
      const wsString = queryString.replace('http', 'ws');
      const host = (options.length > 1 && options[1]?.websocket?.[0]) || wsString;
      const webSocketOptions = options[1]?.websocket || [host];
      const ws = new WebSocket(...webSocketOptions);
      return {
        ws,
        on: (e: (args: any) => void) => {
          ws.onmessage = (event:any) => {
            if(event.data){
              const parsed = JSON.parse(event.data)
              const data = parsed.data
              if (data) {
                seekForAliases(data);
              }
              return e(data);
            }
          };
        },
        off: (e: (args: any) => void) => {
          ws.onclose = e;
        },
        error: (e: (args: any) => void) => {
          ws.onerror = e;
        },
        open: (e: () => void) => {
          ws.onopen = e;
        },
      };
    } catch {
      throw new Error('No websockets implemented');
    }
  };


export const Thunder = (fn: FetchFunction, subscriptionFn: SubscriptionFunction) => ({
  query: fullChainConstructor(fn,'query', 'query_root'),
mutation: fullChainConstructor(fn,'mutation', 'mutation_root'),
subscription: fullSubscriptionConstructor(subscriptionFn,'subscription', 'subscription_root')
});

export const Chain = (...options: chainOptions) => ({
  query: fullChainConstructor(apiFetch(options),'query', 'query_root'),
mutation: fullChainConstructor(apiFetch(options),'mutation', 'mutation_root'),
subscription: fullSubscriptionConstructor(apiSubscription(options),'subscription', 'subscription_root')
});
export const Zeus = {
  query: (o:ValueTypes["query_root"], operationName?: string) => queryConstruct('query', 'query_root', operationName)(o),
mutation: (o:ValueTypes["mutation_root"], operationName?: string) => queryConstruct('mutation', 'mutation_root', operationName)(o),
subscription: (o:ValueTypes["subscription_root"], operationName?: string) => queryConstruct('subscription', 'subscription_root', operationName)(o)
};
export const Selectors = {
  query: ZeusSelect<ValueTypes["query_root"]>(),
mutation: ZeusSelect<ValueTypes["mutation_root"]>(),
subscription: ZeusSelect<ValueTypes["subscription_root"]>()
};
  

export const Gql = Chain('http://localhost:8080/v1/graphql')